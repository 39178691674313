.tooltip {
	padding: 12px 15px 15px;
	font-size: 15px;
	font-weight: $font-weight-semibold;
	text-align: center;
	background: $color-blue-extradark;
	border-radius: $border-radius-basic;
	user-select: none;

	&::after {
		position: absolute;
		top: 100%;
		left: calc(50% - 8px);
		display: block;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		border-top-color: $color-blue-extradark;
		content: "";
	}
}
