.tabs {
	&--shadowed {
		box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .2);
	}

	&__items {
		margin: 30px 0;
	}
}

.tab {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;

	&__item {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}
