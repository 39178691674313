@font-face {
	font-family: "SpaceMono";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/SpaceMonoRegular.woff2") format("woff2");
}

@font-face {
	font-family: "SpaceMono";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/SpaceMonoBold.woff2") format("woff2");
}

@font-face {
	font-family: "DrukWide-Medium";
	font-style: normal;
	font-weight: bold;
	src: url("../fonts/DrukWide-Medium.woff2") format("woff2"), url("../fonts/DrukWide-Medium.woff") format("woff"),
		url("../fonts/DrukWide-Medium.ttf") format("truetype");
	line-height: normal;
	letter-spacing: normal;
	font-stretch: normal;
}
