.notifications {
    &__container {
        width: 400px;
        max-height: 500px;
        overflow-y: auto;
        background-color: #fff;
    }

    &__loader {
        height: 120px;
    }

    &__item {
        margin-bottom: 10px;
    }

    &__title {
        font-size: .875rem;
        font-weight: 600;
        line-height: 1rem;
    }

    &__text {
        padding-bottom: 8px;
        font-size: .75rem;

    }

    &__link {
        white-space: nowrap;
    }

    &__footer {
        font-size: .625rem;
        color: rgba(0, 0, 0, .6);
    }

    &__badge.v-badge {
        margin-top: 0;
    }
}
