@import "../helpers/variables";

.dropdown {
	width: 32px;
	height: 32px;

	border-radius: 4px;

	&__open {
		border-radius: 4px;
	}

	&__submenu {
		display: block;
	}
}

.theme--light.v-list {
	background: #fff;
}

tr {
	.dropdown {
		margin: 0 16px;

		@media (max-width: $list-sm-breakpoint) {
			margin: 0;
		}
	}

	&:hover .dropdown {
		border: solid 1px rgba(#fff, .3);

		&:hover {
			border: solid 1px rgba(#fff, 1);
		}
	}
}
