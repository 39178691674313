.input-field {
	$if: #{&};

	position: relative;
	display: block;
	margin-top: 40px;
	margin-bottom: 40px;
	cursor: text;

	&__legend {
		font-size: 14px;
		font-weight: $font-weight-black;
		text-transform: uppercase;
	}

	&__input-container {
		@include fancyBorderAnimationOnInteraction(".focused", 0);
		position: relative;
		z-index: 1;
	}

	&__input {
		@include formElementStyleReset;
		width: 100%;
		height: 30px;
		padding: 5px 0;
		transition: height $transition-duration-quick $transition-timing-function-default;
	}

	&__error {
		font-size: 14px;
		font-weight: $font-weight-black;
		color: $color-red-basic;
	}

	&.empty:not(.focused) {
		#{$if}__input {
			height: 0;
		}
	}
}
