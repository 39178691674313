@import "../helpers/variables";

.badge {
	// @TODO: add subtle @keyframe animation to imitate LED "pulsation"
	position: relative;
	bottom: 1px;
	display: inline-block;
	width: .563em;
	height: .563em;
	vertical-align: middle;
	border-radius: 100px;

	@media (max-width: $list-md-breakpoint) {
		bottom: 0;
	}

	/**
	 * Colors:
	 */

	&--online {
		background: $color-green-basic;
		box-shadow: 0 0 20px rgba($color-green-basic, .45);
	}

	&--offline {
		background: #f00;
		box-shadow: 0 0 20px rgba(#f00, .45);
	}

	&--blank {
		// a badge placeholder, it shows nothing by design,
		// just adds a bit of padding
		background: transparent;
	}

	/**
	 * Sizes:
	 */

	&--smaller {
		width: .25em;
		height: .25em;
	}

	/**
	 * Other properties:
	 */

	&--inline {
		margin-right: .2em;
		margin-left: .2em;
	}

	&--left {
		margin-right: 1em;
	}

	&--vertical-middle {
		bottom: -1px;
		margin-top: auto;
		margin-bottom: auto;

		@media (max-width: $list-lg-breakpoint) {
			bottom: 0;
		}
	}
}
