.main-header {
    &__logo {
        position: absolute;
        top: 0;
        left: 75 / 1920 * 100vw;

        &__image svg {
            width: 120px;
            height: 40px;
            fill: #fff;
        }

        &__headline {
            width: 0;
            height: 0;
            overflow: hidden;
        }
    }

    &__navigation {
        position: absolute;
        top: 60 / 1920 * 100vw;
    }

    &__links {
        position: absolute;
        right: 125 / 1920 * 100vw;
    }

    &__toolbar {
        position: absolute;
        right: calc((145 / 1920 * 100vw) - 12px);
    }
}
