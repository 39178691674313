.plain-banner {
	text-align: center;

	&__icon {
		svg {
			width: 270px;
			height: 270px;
			margin-bottom: 30px;
		}

		&--smaller svg {
			width: 150px;
			height: 150px;
		}
	}

	&__container {
		max-width: 520px;
		margin: auto;

		@include break(xs) {
			width: initial;
		}
	}

	&__description {
		margin: 20px 0;
		font-size: 16px;
		font-style: normal;
		font-weight: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		font-stretch: normal;

		&--center {
			text-align: center;
		}
	}

	&__row {
		padding: 7px 0;

		&--wbreak {
			padding: 7px 0 36px;
		}
	}

	&__footer {
		display: inline;
		font-size: 12px;

		&--text {
			padding-right: 22px;
			opacity: .5;
		}
	}
}
