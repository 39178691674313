/**
 * Fade:
 */

.fade-enter-active,
.fade-leave-active {
	transition: opacity $transition-duration-basic $transition-timing-function-default;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

/**
 * Fade and fall:
 */

.fade-and-fall-enter-active,
.fade-and-fall-leave-active {
	transition: opacity $transition-duration-slower $transition-timing-function-default, transform $transition-duration-slower $transition-timing-function-default;
}

.fade-and-fall-enter,
.fade-and-fall-leave-to {
	opacity: 0;
}

.fade-and-fall-enter {
	transform: translateY(-10px);
}

.fade-and-fall-leave-to {
	transform: translateY(30px);
}

/**
 * For Group v-bottom-sheet
 * Bottom to top:
 */

.bottom-to-top-enter-active,
.bottom-to-top-leave-active {
	transition: .3s cubic-bezier(.25, .8, .25, 1);

	@media (max-width: 650px) {
		transition: .2s cubic-bezier(.25, .8, .25, 1);
	}
}

.bottom-to-top-enter,
.bottom-to-top-leave-to {
	transform: translateY(80px);

	@media (max-width: 650px) {
		transform: translateY(120px);
	}
}
