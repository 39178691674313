.is-hidden,
.no-display {
	display: none;
}

[v-cloak] {
	display: none;
}

.left-input {
	margin-right: 20px;

	@include break(xs) {
		margin-right: 0;
	}
}

.right-input {
	margin-left: 20px;

	@include break(xs) {
		margin-left: 0;
	}
}

.input-layout {
	flex-direction: row;

	@include break(xs) {
		flex-direction: column;
	}
}
