//@import "~vuetify/src/styles/styles";

@import "helpers/variables";
@import "helpers/functions";
@import "helpers/mixins";
@import "helpers/animations";
@import "helpers/fonts";

@import "base/reset";
@import "base/scaffolding";

@import "helpers/transitions";
@import "helpers/layout-helpers";

@import "components/navigation";
@import "components/table";
@import "components/badge";
@import "components/icon";
@import "components/tooltip";
@import "components/view-switcher";
@import "components/modal";
@import "components/title";
@import "components/link";
@import "components/button";
@import "components/phone";
@import "components/device-grid";
@import "components/loader";
@import "components/plain-banner";
@import "components/device-details-modal";
@import "components/input-field";
@import "components/page";
@import "components/white-page";
@import "components/tabs";
@import "components/dropdown";
@import "components/notifications";

@import "layouts/header";

[v-cloak] {
	display: none;
}
