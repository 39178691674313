.navigation {
	&__item {
		font-family: $font-stack-default;
		font-size: 12px;
		font-weight: $font-weight-semibold;
		letter-spacing: 0;
		text-transform: unset;

		&.v-btn:not(.v-btn--round).v-size--default {
			padding: 0 20 / 1920 * 100vw;
		}

		&.v-btn.v-btn--active::before {
			opacity: 0;
		}

		&.v-btn.v-btn--text:hover::before {
			opacity: 0;
		}

		&.v-btn.v-btn--text:focus::before {
			opacity: 0;
		}

		&.v-btn.v-size--default {
			font-size: 12px;
		}

		&-icon {
			opacity: .15;

			&.v-btn.v-btn--active {
				opacity: 1;
			}
		}

		&--dark {
			display: inline-flex;
			padding: 15px 25px;
			font-size: 15px;
			color: inherit;
		}
	}

	&__link {
		display: block;
		padding: 10px 20 / 1920 * 100vw;

		font-family: $font-stack-default;
		font-size: 15px;
		font-weight: $font-weight-semibold;

		color: #fff;
		text-decoration: none;

		&__dark {
			padding: 25px;
			color: inherit;
		}

		&__disabled {
			color: #808080;
		}
	}
}
