.link {
	@include fancyBorderAnimationOnInteraction(":hover", -2px);

	position: relative;
	z-index: 1;
	color: $color-blue-dark;
	text-decoration: none;
	transition: color $transition-duration-basic $transition-timing-function-default;

	&--clean {
		&::before {
			height: 0;
		}
	}

	&--danger {
		&::after {
			background: currentColor;
		}

		&:hover {
			color: $color-red-basic;

			&::after {
				transition-delay: 0s;
			}
		}
	}

	&--white {
		color: #fff;

		&::before {
			background: #fff;
		}

		&:active {
			&::after {
				background: #fff;
			}
		}
	}

	&--tiny {
		font-size: 12px;
	}
}

a:not([class]) {
	@extend .link;
}
