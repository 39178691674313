/**
 * MIXINS FROM BOURBON:
 */
@keyframes loader {
  0% {
    transform: rotate(460deg); }
  100% {
    transform: rotate(-360deg); } }

@font-face {
  font-family: "SpaceMono";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/SpaceMonoRegular.woff2") format("woff2"); }

@font-face {
  font-family: "SpaceMono";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/SpaceMonoBold.woff2") format("woff2"); }

@font-face {
  font-family: "DrukWide-Medium";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/DrukWide-Medium.woff2") format("woff2"), url("../fonts/DrukWide-Medium.woff") format("woff"), url("../fonts/DrukWide-Medium.ttf") format("truetype");
  line-height: normal;
  letter-spacing: normal;
  font-stretch: normal; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
.application,
.application-wrap {
  padding: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.theme--light.v-application,
.v-application,
.v-application--wrap {
  all: unset;
  -webkit-text-fill-color: initial; }

.v-text-field__details {
  overflow: visible; }

.v-messages__message {
  line-height: 1; }

.theme--light.v-sheet {
  background-color: transparent; }

img[src=''], img:not([src]) {
  opacity: 0; }

* {
  outline: none;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  *::after, *::before {
    box-sizing: inherit; }

html {
  height: 100%;
  overflow: auto; }

body {
  min-height: 100%;
  padding-top: 200px;
  font-family: "SpaceMono", sans-serif;
  font-weight: 400;
  color: #fff;
  background: linear-gradient(45deg, #3b2634, #202631) fixed; }
  @media (max-width: 599px) {
    body {
      padding-top: 110px; } }

button {
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  border-radius: 0;
  appearance: none;
  cursor: pointer; }

label {
  cursor: pointer; }

input[type="button"],
input[type="submit"],
button,
select {
  user-select: none; }

p {
  margin-top: .4em;
  margin-bottom: .4em;
  font-weight: 400; }

b,
strong {
  font-weight: 900; }

hr {
  height: 1px;
  padding: 0;
  margin: 20px 0;
  background: #20242f;
  border: 0; }

small {
  color: #868791; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  border: 0;
  opacity: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/**
 * Fade:
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out; }

.fade-enter,
.fade-leave-to {
  opacity: 0; }

/**
 * Fade and fall:
 */
.fade-and-fall-enter-active,
.fade-and-fall-leave-active {
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

.fade-and-fall-enter,
.fade-and-fall-leave-to {
  opacity: 0; }

.fade-and-fall-enter {
  transform: translateY(-10px); }

.fade-and-fall-leave-to {
  transform: translateY(30px); }

/**
 * For Group v-bottom-sheet
 * Bottom to top:
 */
.bottom-to-top-enter-active,
.bottom-to-top-leave-active {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  @media (max-width: 650px) {
    .bottom-to-top-enter-active,
    .bottom-to-top-leave-active {
      transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); } }

.bottom-to-top-enter,
.bottom-to-top-leave-to {
  transform: translateY(80px); }
  @media (max-width: 650px) {
    .bottom-to-top-enter,
    .bottom-to-top-leave-to {
      transform: translateY(120px); } }

.is-hidden,
.no-display {
  display: none; }

[v-cloak] {
  display: none; }

.left-input {
  margin-right: 20px; }
  @media (max-width: 599px) {
    .left-input {
      margin-right: 0; } }

.right-input {
  margin-left: 20px; }
  @media (max-width: 599px) {
    .right-input {
      margin-left: 0; } }

.input-layout {
  flex-direction: row; }
  @media (max-width: 599px) {
    .input-layout {
      flex-direction: column; } }

.navigation__item {
  font-family: "SpaceMono", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: unset; }
  .navigation__item.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 1.04167vw; }
  .navigation__item.v-btn.v-btn--active::before {
    opacity: 0; }
  .navigation__item.v-btn.v-btn--text:hover::before {
    opacity: 0; }
  .navigation__item.v-btn.v-btn--text:focus::before {
    opacity: 0; }
  .navigation__item.v-btn.v-size--default {
    font-size: 12px; }
  .navigation__item-icon {
    opacity: .15; }
    .navigation__item-icon.v-btn.v-btn--active {
      opacity: 1; }
  .navigation__item--dark {
    display: inline-flex;
    padding: 15px 25px;
    font-size: 15px;
    color: inherit; }

.navigation__link {
  display: block;
  padding: 10px 1.04167vw;
  font-family: "SpaceMono", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  text-decoration: none; }
  .navigation__link__dark {
    padding: 25px;
    color: inherit; }
  .navigation__link__disabled {
    color: #808080; }

/**
 * MIXINS FROM BOURBON:
 */
.table {
  right: 7.55208vw;
  left: 3.90625vw;
  margin-top: 50px;
  /**
	 * Header shadow:
	 */
  /**
	 * Sticky header:
	 */ }
  .table__row {
    transition: background 0.2s ease-in-out; }
  .table__cell {
    padding: 42px 0 42px 16px; }
    @media (max-width: 1100px) {
      .table__cell {
        padding: 22px 0 22px 16px; } }
    @media (max-width: 500px) {
      .table__cell {
        padding-left: 0; } }
    .table__cell--center {
      text-align: center; }
    .table__cell--phone {
      position: relative;
      height: 100px; }
    .table__cell--secondary {
      opacity: .2; }
    .table__cell--on-hover:not(:hover) {
      opacity: 0; }
    .table__cell--status {
      padding-left: 16px; }
      @media (max-width: 500px) {
        .table__cell--status {
          padding-left: 0; } }
  .table__phone {
    position: relative;
    top: 18px; }
  .table__header-shadow {
    display: none; }
  .table--with-header-shadow {
    position: absolute; }
  .table--with-header-shadow .table__header-shadow {
    position: absolute;
    top: 68px;
    right: -7.55208vw;
    left: -3.90625vw;
    z-index: -1;
    display: block;
    height: 60px;
    background: transparent linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)); }
  .table__header-loading-linear {
    position: absolute;
    right: -7.55208vw;
    left: -3.90625vw;
    width: auto; }
  .table__header-loading-circular {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    margin: auto; }
  @supports (position: sticky) {
    .table {
      /*@include isFirefox {
			&--sticky &__header-shadow {
				position: absolute;
			}

			&--sticky &__header::after {
				display: none;
			}
		}*/ }
      .table--sticky .table__header-shadow {
        position: fixed; }
        @media (max-width: 500px) {
          .table--sticky .table__header-shadow {
            position: absolute; } }
      .table--sticky .table__header::after {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 3;
        display: block;
        height: 68px;
        background: linear-gradient(45deg, #3b2634, #202631) fixed;
        content: ""; }
        @media (max-width: 500px) {
          .table--sticky .table__header::after {
            position: absolute; } } }

.theme--dark.v-data-table thead tr:last-child th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  height: 67px;
  background: linear-gradient(45deg, #3b2634, #202631) fixed;
  border-bottom: 0; }

@-moz-document url-prefix() {
  .theme--dark.v-data-table thead tr:last-child th {
    height: 54px; } }
  @media (max-width: 500px) {
    .theme--dark.v-data-table thead tr:last-child th {
      position: relative;
      background: transparent; } }

.v-data-table-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  height: 67px;
  background: linear-gradient(45deg, #3b2634, #202631) fixed; }
  @media (max-width: 500px) {
    .v-data-table-header {
      position: relative;
      background: transparent; } }

.theme--dark.v-data-table,
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: transparent; }

.v-data-table td {
  height: 60px;
  padding: 0;
  font-size: 16px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer; }

.v-data-table td:first-child {
  padding-left: 16px; }
  @media (max-width: 500px) {
    .v-data-table td:first-child {
      padding-left: 0; } }

.v-data-table th {
  padding-top: 16px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: baseline; }
  @media (max-width: 500px) {
    .v-data-table th {
      padding-left: 0; } }

.v-data-table__wrapper {
  margin-bottom: 82px;
  overflow: visible; }

.theme--dark.v-data-table tbody tr:not(:last-child) {
  border-bottom: 0; }

.theme--dark.v-data-table tbody tr:not(.v-row-group__header) + tr:not(.v-row-group__header) {
  border-top: 1px solid rgba(255, 255, 255, 0.12); }

.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: 0; }

.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expand-row):not(.v-data-table__empty-wrapper):not(.v-row-group__header) {
  background: #1b1f27; }

.theme--dark.v-data-table .v-row-group__header {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 0;
  height: 90px;
  background: transparent; }
  @media (max-width: 599px) {
    .theme--dark.v-data-table .v-row-group__header {
      height: 60px; } }

.table .v-simple-checkbox {
  padding-left: 2px;
  line-height: inherit; }

.table .mdi-checkbox-blank-outline {
  font-size: 20px;
  color: #fff;
  opacity: .3; }

.table tr .mdi-checkbox-marked,
.table tr .mdi-minus-box {
  font-size: 20px;
  color: #145cff; }

.table tr .mdi-checkbox-marked::before,
.table tr .mdi-minus-box::before {
  background: linear-gradient(#fff, #fff) no-repeat, linear-gradient(#fff, #fff) no-repeat;
  background-position: 4px;
  background-size: 12px 12px; }

.table tr th .mdi-checkbox-blank-outline:hover,
.table tr:hover td .mdi-checkbox-blank-outline {
  opacity: 1; }

.badge {
  position: relative;
  bottom: 1px;
  display: inline-block;
  width: .563em;
  height: .563em;
  vertical-align: middle;
  border-radius: 100px;
  /**
	 * Colors:
	 */
  /**
	 * Sizes:
	 */
  /**
	 * Other properties:
	 */ }
  @media (max-width: 1100px) {
    .badge {
      bottom: 0; } }
  .badge--online {
    background: #00ff1e;
    box-shadow: 0 0 20px rgba(0, 255, 30, 0.45); }
  .badge--offline {
    background: #f00;
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.45); }
  .badge--blank {
    background: transparent; }
  .badge--smaller {
    width: .25em;
    height: .25em; }
  .badge--inline {
    margin-right: .2em;
    margin-left: .2em; }
  .badge--left {
    margin-right: 1em; }
  .badge--vertical-middle {
    bottom: -1px;
    margin-top: auto;
    margin-bottom: auto; }
    @media (max-width: 1450px) {
      .badge--vertical-middle {
        bottom: 0; } }

/**
 * MIXINS FROM BOURBON:
 */
.icon svg {
  position: relative;
  bottom: 1px;
  display: inline-block;
  vertical-align: middle;
  transition: fill 0.1s ease-in-out; }

.icon--white svg {
  fill: #fff; }

.icon--pale {
  opacity: .15; }

.icon--large svg {
  width: 30px;
  height: 30px; }

.icon--medium svg {
  width: 25px;
  height: 25px; }

.icon--small svg {
  width: 20px;
  height: 20px; }

.icon--smaller svg {
  width: 15px;
  height: 15px; }

.icon--tiny svg {
  width: 10px;
  height: 10px; }

.icon--huge svg {
  width: 150px;
  height: 40px; }

.icon--payment svg {
  width: 150px;
  height: 40px; }
  @media (max-width: 599px) {
    .icon--payment svg {
      width: 120px;
      height: 40px; } }

.icon--on-left svg {
  margin-right: 1em; }

.icon--on-left-nav svg {
  margin-right: .5em; }

.icon--on-right svg {
  margin-left: 1em; }

.icon--placeholder svg {
  opacity: 0;
  visibility: hidden; }

.tooltip {
  padding: 12px 15px 15px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  background: #111319;
  border-radius: 3px;
  user-select: none; }
  .tooltip::after {
    position: absolute;
    top: 100%;
    left: calc(50% - 8px);
    display: block;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: #111319;
    content: ""; }

.view-switcher {
  position: fixed;
  right: 40px;
  bottom: 60px;
  display: flex; }
  .view-switcher__item {
    position: relative; }
  .view-switcher__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    border: 0;
    opacity: 0; }
  .view-switcher__button {
    display: flex;
    align-items: center;
    width: 56px;
    height: 40px;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 0 50px transparent, 0 0 20px transparent;
    transition: box-shadow 0.4s ease-in-out; }
    .view-switcher__button:hover {
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.4), 0 0 20px rgba(0, 0, 0, 0.1); }
  .view-switcher__icon {
    margin: 0 auto; }
  .view-switcher__icon svg {
    display: block;
    width: 24px;
    height: 20px;
    opacity: .25;
    transition: opacity 0.4s ease-in-out;
    fill: #fff; }
  .view-switcher__button:hover .view-switcher__icon svg,
  .view-switcher__input:checked + .view-switcher__button .view-switcher__icon svg {
    opacity: 1; }
  .view-switcher__tooltip {
    position: absolute;
    bottom: calc(100% + 25px);
    left: 50%;
    display: none;
    min-width: 120px;
    transform: translateX(-50%); }
  .view-switcher__button:hover + .view-switcher__tooltip {
    display: block; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 31;
  display: grid;
  overflow: auto;
  background: rgba(32, 36, 47, 0.5); }
  .modal__container {
    width: 680px;
    margin: auto; }
    .modal__container--row {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 0; }
    @media (max-width: 680px) {
      .modal__container {
        width: inherit;
        margin: auto 0; } }
  .modal__body {
    position: relative;
    width: 530px;
    overflow: hidden;
    color: #20242f;
    background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)) 0 100%;
    background-attachment: local, local, scroll, scroll;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    border-radius: 5px;
    box-shadow: 0 0 85px rgba(0, 0, 0, 0.5); }
    .modal__body.loader--cloak {
      min-height: 200px; }
    @media (max-width: 680px) {
      .modal__body {
        width: inherit; } }
  .modal__footer {
    position: relative;
    display: flex;
    width: 530px;
    padding: 3em 0; }
    .modal__footer--spread {
      justify-content: space-between; }
    @media (max-width: 680px) {
      .modal__footer {
        align-items: center;
        flex-direction: column;
        width: inherit;
        line-height: 30px;
        text-align: center; } }
  .modal__section {
    margin: 35px; }
    @media (max-height: 800px) {
      .modal__section {
        margin: 25px; } }
    @media (max-height: 700px) {
      .modal__section {
        margin: 20px; } }
    .modal__section--flush {
      margin-right: 0;
      margin-left: 0; }
    .modal__section--map {
      height: 300px; }
    .modal__section--alternate {
      background: #f9f9f9; }
  .modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .modal__title {
    flex: 1 0 200px; }
  .modal__close-button {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    text-align: center; }
    .modal__close-button__icon svg {
      display: inline-block;
      width: 15px;
      height: 15px; }
  .modal--spacious .modal__body,
  .modal--spacious .modal__footer {
    width: 680px; }
    @media (max-width: 680px) {
      .modal--spacious .modal__body,
      .modal--spacious .modal__footer {
        flex-direction: column;
        width: inherit;
        padding-bottom: 0; } }
  .modal--spacious .modal__header,
  .modal--spacious .modal__section {
    margin: 70px 90px; }
    @media (max-height: 800px) {
      .modal--spacious .modal__header,
      .modal--spacious .modal__section {
        margin: 50px 60px; } }
    @media (max-height: 700px) {
      .modal--spacious .modal__header,
      .modal--spacious .modal__section {
        margin: 40px; } }
    @media (max-width: 680px) {
      .modal--spacious .modal__header,
      .modal--spacious .modal__section {
        width: inherit;
        padding-right: 15px;
        padding-left: 15px;
        margin: 40px 0; } }

/**
 * MIXINS FROM BOURBON:
 */
.title--huge {
  font-family: "DrukWide-Medium", sans-serif;
  font-size: 62px;
  font-weight: 300; }

.title--1 {
  font-family: "DrukWide-Medium";
  font-size: 42px; }
  @media (max-width: 599px) {
    .title--1 {
      font-size: 28px; } }

.title--2 {
  font-family: "DrukWide-Medium";
  font-size: 28px;
  color: #43414e; }

.title--3 {
  font-family: "DrukWide-Medium";
  font-size: 13px;
  font-weight: 900;
  color: #43414e; }

.title--4 {
  font-family: "SpaceMono";
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase; }

.title--5 {
  font-family: "DrukWide-Medium";
  font-size: 18px;
  font-weight: 900;
  color: #43414e; }

.title--device-info {
  font-family: "SpaceMono";
  font-size: 16px; }
  @media (max-width: 1440px) {
    .title--device-info {
      font-size: 14px; } }
  @media (max-width: 960px) {
    .title--device-info {
      font-size: 13px; } }

.title .icon {
  fill: currentColor; }

.link, a:not([class]) {
  position: relative;
  z-index: 1;
  color: #1b1f27;
  text-decoration: none;
  transition: color 0.2s ease-in-out; }
  .link::before, a:not([class])::before, .link::after, a:not([class])::after {
    position: absolute;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    display: block;
    height: 1px;
    background: #1b1f27;
    content: "";
    transition: transform 0.2s ease-in-out; }
  .link::before, a:not([class])::before {
    transform: scaleX(1);
    transition-delay: 0.2s;
    transform-origin: right center; }
  .link::after, a:not([class])::after {
    bottom: -3px;
    height: 2px;
    background: #00aeef;
    transform: scaleX(0);
    transition-delay: 0s;
    transform-origin: left center; }
  .link:hover::before, a:not([class]):hover::before {
    transform: scaleX(0);
    transition-delay: 0s; }
  .link:hover::after, a:not([class]):hover::after {
    transform: scaleX(1);
    transition-delay: 0.2s; }
  .link:active::after, a:not([class]):active::after {
    background: #20242f; }
  .link--clean::before {
    height: 0; }
  .link--danger::after {
    background: currentColor; }
  .link--danger:hover {
    color: #e74c3c; }
    .link--danger:hover::after {
      transition-delay: 0s; }
  .link--white {
    color: #fff; }
    .link--white::before {
      background: #fff; }
    .link--white:active::after {
      background: #fff; }
  .link--tiny {
    font-size: 12px; }

.button--edit .icon {
  fill: #868791; }

.button--edit:hover .icon, .button--edit:focus .icon, .button--edit:active .icon {
  fill: #1b1f27; }

.button--settings .icon {
  fill: rgba(255, 255, 255, 0.5); }

.button--settings:hover .icon, .button--settings:focus .icon {
  fill: white; }

.button--primary {
  padding: 1em;
  font-family: "SpaceMono";
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  background: #00aeef;
  border-radius: 5px; }

.button--secondary {
  padding: 1em;
  font-family: "SpaceMono";
  font-size: 15px;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  background: #20242f;
  border-radius: 5px; }

.button--huge {
  padding: 1.75em 1em;
  border-radius: 10px; }

.button--flush {
  width: 100%; }

.v-btn {
  margin: 0; }

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 1.5625vw; }

.v-btn:not(.v-btn--round).v-size--large {
  height: 44px;
  padding: 0 32px;
  font-size: 15px; }

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 56px; }

.v-btn__content {
  font-weight: bold;
  letter-spacing: normal; }

.phone {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: svg-load("phone.svg") center no-repeat;
  background-size: 100% 100%; }
  .phone__screen {
    display: block;
    max-width: 279px;
    max-height: 585px;
    filter: drop-shadow(0 0 28px rgba(0, 0, 0, 0.5));
    object-fit: scale-down; }
    @media (max-width: 1820px) {
      .phone__screen {
        max-width: 14rem;
        max-height: 30rem; } }
    @media (max-width: 1640px) {
      .phone__screen {
        max-width: 12rem;
        max-height: 26rem; } }
    @media (max-width: 1440px) {
      .phone__screen {
        max-width: 10rem;
        max-height: 21rem; } }
    @media (max-width: 1060px) {
      .phone__screen {
        max-width: 9rem;
        max-height: 19rem; } }
    .phone__screen-thumbnail {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: fill;
      background-color: black; }
  .phone--thumbnail {
    width: 22.32px;
    height: 46.8px;
    padding: 0px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1px;
    margin: auto;
    background: none; }
  .phone--medium {
    background-size: 100% 100%; }
  .phone--add {
    width: 279px;
    height: 585px;
    padding: 1px;
    filter: drop-shadow(0 0 28px rgba(0, 0, 0, 0.5));
    position: relative;
    padding-top: 46px;
    padding-bottom: 46px;
    background-image: svg-load("phone-add-button.svg"), svg-load("phone-add-base.svg"); }
    @media (max-width: 1820px) {
      .phone--add {
        width: 14rem;
        height: 30rem; } }
    @media (max-width: 1640px) {
      .phone--add {
        width: 12rem;
        height: 26rem; } }
    @media (max-width: 1440px) {
      .phone--add {
        width: 10rem;
        height: 21rem; } }
    @media (max-width: 1060px) {
      .phone--add {
        width: 9rem;
        height: 19rem; } }
    .phone--add::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      background: svg-load("phone-add-button-hover.svg") center no-repeat;
      content: "";
      opacity: 0;
      transform: scale(0.95) translateZ(0);
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out; }
    .phone--add:hover::after {
      opacity: 1;
      transform: scale(1) translateZ(0); }

.device-grid {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-around; }
  .device-grid--align-left {
    justify-content: flex-start; }
  .device-grid__group {
    position: relative;
    top: 1vw;
    margin-left: 3.90625vw; }
    .device-grid__group--bottom-row {
      width: 100%;
      border: solid 1px rgba(255, 255, 255, 0.14); }
    .device-grid__group--no-bottom-row {
      width: 100%;
      border: solid 1px rgba(255, 255, 255, 0); }
  .device-grid__device {
    display: flex;
    flex-basis: content;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 30px;
    margin: 1.5vw 2px;
    text-align: center;
    border: 4px solid transparent; }
    @media (max-width: 1640px) {
      .device-grid__device {
        padding-top: 10px; } }
    @media (max-width: 1440px) {
      .device-grid__device {
        padding-top: 0; } }
    @media (max-width: 960px) {
      .device-grid__device {
        margin: 3vw 2px; } }
    .device-grid__device--add {
      align-items: center;
      padding: 30px 0 0; }
      @media (max-width: 1640px) {
        .device-grid__device--add {
          padding-top: 10px; } }
      @media (max-width: 1440px) {
        .device-grid__device--add {
          padding: 30px 30px 25px; } }
    .device-grid__device li {
      display: block;
      float: left; }
    .device-grid__device .device-grid__phone {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .device-grid__device:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.05); }
      .device-grid__device:hover .cbx span:first-child {
        border-color: rgba(255, 255, 255, 0.75); }
    .device-grid__device--grouped {
      margin-right: .6vw;
      margin-left: .6vw; }
      @media (max-width: 1820px) {
        .device-grid__device--grouped {
          margin-right: 1vw;
          margin-left: 1vw; } }
      @media (max-width: 1640px) {
        .device-grid__device--grouped {
          margin-right: 1.2vw;
          margin-left: 1.2vw; } }
      @media (max-width: 1440px) {
        .device-grid__device--grouped {
          margin-right: .9vw;
          margin-left: .9vw; } }
      @media (max-width: 1280px) {
        .device-grid__device--grouped {
          margin-right: 4.2vw;
          margin-left: 4.2vw; } }
      @media (max-width: 1185px) {
        .device-grid__device--grouped {
          margin-right: 2.7vw;
          margin-left: 2.7vw; } }
      @media (max-width: 1060px) {
        .device-grid__device--grouped {
          margin-right: 1.5vw;
          margin-left: 1.5vw; } }
      @media (max-width: 960px) {
        .device-grid__device--grouped {
          margin-right: 2px;
          margin-left: 2px; } }
  .device-grid__dropdown {
    position: relative;
    top: -6px;
    padding: 0 20px; }
    @media (max-width: 1640px) {
      .device-grid__dropdown {
        top: 8px;
        padding: 0 15px; } }
    @media (max-width: 1440px) {
      .device-grid__dropdown {
        top: 4px; } }
    @media (max-width: 960px) {
      .device-grid__dropdown {
        top: 1px; } }
  .device-grid__checkbox {
    position: relative;
    top: -1px;
    padding: 0 22px; }
    @media (max-width: 1640px) {
      .device-grid__checkbox {
        top: 14px;
        padding: 0 17px; } }
    @media (max-width: 1440px) {
      .device-grid__checkbox {
        top: 10px; } }
    @media (max-width: 960px) {
      .device-grid__checkbox {
        top: 7px; } }
  .device-grid__status {
    padding-top: 10px;
    font-weight: 400;
    text-transform: unset; }
  .device-grid__screen {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 279px;
    height: 585px;
    -webkit-transform: scale(1);
    -webkit-transition: all .1s ease-out;
    -moz-transform: scale(1);
    -moz-transition: all .1s ease-out;
    transform: scale(1);
    transition: all .1s ease-out; }
    @media (max-width: 1820px) {
      .device-grid__screen {
        width: 14rem;
        height: 30rem; } }
    @media (max-width: 1640px) {
      .device-grid__screen {
        width: 12rem;
        height: 26rem; } }
    @media (max-width: 1440px) {
      .device-grid__screen {
        width: 10rem;
        height: 21rem; } }
    @media (max-width: 1060px) {
      .device-grid__screen {
        width: 9rem;
        height: 19rem; } }
    .device-grid__screen-add {
      margin-top: 34px; }
      @media (max-width: 1640px) {
        .device-grid__screen-add {
          margin-top: 16px; } }
      @media (max-width: 1440px) {
        .device-grid__screen-add {
          margin-top: 14px; } }
      @media (max-width: 960px) {
        .device-grid__screen-add {
          margin-top: 12px; } }
  .device-grid__device--add--title {
    font-family: "DrukWide-Medium";
    font-size: 28px;
    color: #fff; }
    @media (max-width: 1820px) {
      .device-grid__device--add--title {
        font-size: 24px; } }
    @media (max-width: 1440px) {
      .device-grid__device--add--title {
        font-size: 20px; } }
    @media (max-width: 1280px) {
      .device-grid__device--add--title {
        font-size: 16px; } }
  .device-grid__device--add .device-grid__screen {
    transform: scale(0.9); }
  .device-grid__device--link .device-grid__screen__phone {
    cursor: pointer; }
  .device-grid__info {
    margin: 18px 0; }
    @media (max-width: 1640px) {
      .device-grid__info {
        margin: 16px 0; } }
    @media (max-width: 1440px) {
      .device-grid__info {
        margin: 14px 0; } }
    @media (max-width: 960px) {
      .device-grid__info {
        margin: 12px 0; } }
    .device-grid__info-row {
      margin: 10px 0; }
      @media (max-width: 960px) {
        .device-grid__info-row {
          margin: 6px 0; } }
      .device-grid__info-row--grey {
        color: #868791; }
  .device-grid__name {
    display: inline-flex;
    margin-top: 2px;
    margin-bottom: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 1640px) {
      .device-grid__name {
        margin: 16px 0; } }
    @media (max-width: 1440px) {
      .device-grid__name {
        margin: 14px 0; } }
    @media (max-width: 960px) {
      .device-grid__name {
        margin: 12px 0; } }
  .device-grid__settings-button {
    margin-right: -29px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out; }
  .device-grid__device:hover .device-grid__settings-button {
    opacity: 1; }

.v-application ol,
.v-application ul {
  padding-left: 0; }

.device-grid .dropdown {
  border: solid 1px rgba(255, 255, 255, 0.14); }
  .device-grid .dropdown:hover {
    border: solid 1px white; }

.checked-device {
  background-color: rgba(20, 92, 255, 0.1);
  border: 4px solid #145cff;
  border-radius: 8px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.05); }
  .checked-device__screen {
    -webkit-transform: scale(0.9);
    -webkit-transition: all .1s ease-in;
    -moz-transform: scale(0.9);
    -moz-transition: all .1s ease-in;
    transform: scale(0.9);
    transition: all .1s ease-in; }
  .checked-device:hover {
    background-color: rgba(20, 92, 255, 0.25); }

.cbx {
  margin: auto;
  -webkit-user-select: none;
  cursor: pointer;
  user-select: none; }

.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0); }

.cbx span:first-child {
  position: relative;
  width: 21px;
  height: 21px;
  vertical-align: middle;
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  transform: scale(1);
  transition: all .2s ease; }

.cbx span:first-child svg {
  position: absolute;
  top: 4px;
  left: 3px;
  transform: translate3d(0);
  transition: all .1s ease;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px; }

.cbx span:first-child::before {
  display: block;
  width: 100%;
  height: 100%;
  background: #506eec;
  border-radius: 50%;
  content: "";
  opacity: 1;
  transform: scale(0); }

.cbx span:last-child {
  padding-left: 8px; }

.inp-cbx:checked + .cbx span:first-child {
  background: #145cff;
  border-color: #145cff;
  animation: wave .4s ease; }

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0; }

.inp-cbx:checked + .cbx span:first-child::before {
  opacity: 0;
  transform: scale(3.5);
  transition: all .6s ease; }

@keyframes wave {
  50% {
    transform: scale(0.9); } }

.loader {
  position: relative; }
  .loader::before, .loader::after {
    position: absolute;
    display: block;
    content: ""; }
  .loader::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.75); }
  .loader::after {
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    z-index: 11;
    width: 64px;
    height: 64px;
    pointer-events: none;
    background: url("data:image/svg+xml;charset=UTF-8,<svg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'><path d='M9.12 32c0 12.637 10.243 22.88 22.88 22.88 12.637 0 22.88-10.243 22.88-22.88C54.88 19.363 44.638 9.12 32 9.12' stroke='#1b1f27' stroke-width='2.5' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>");
    background-size: 64px 64px;
    animation: loader .75s ease-in-out infinite alternate; }
  .loader--small::after {
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    width: 32px;
    height: 32px;
    background-size: 32px 32px; }
  .loader--alternate::before {
    background: rgba(27, 31, 39, 0.75); }
  .loader--alternate::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'><path d='M9.12 32c0 12.637 10.243 22.88 22.88 22.88 12.637 0 22.88-10.243 22.88-22.88C54.88 19.363 44.638 9.12 32 9.12' stroke='#fff' stroke-width='2.5' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>"); }
  .loader--cloak > * {
    display: none; }

.plain-banner {
  text-align: center; }
  .plain-banner__icon svg {
    width: 270px;
    height: 270px;
    margin-bottom: 30px; }
  .plain-banner__icon--smaller svg {
    width: 150px;
    height: 150px; }
  .plain-banner__container {
    max-width: 520px;
    margin: auto; }
    @media (max-width: 599px) {
      .plain-banner__container {
        width: initial; } }
  .plain-banner__description {
    margin: 20px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    font-stretch: normal; }
    .plain-banner__description--center {
      text-align: center; }
  .plain-banner__row {
    padding: 7px 0; }
    .plain-banner__row--wbreak {
      padding: 7px 0 36px; }
  .plain-banner__footer {
    display: inline;
    font-size: 12px; }
    .plain-banner__footer--text {
      padding-right: 22px;
      opacity: .5; }

.input-field {
  position: relative;
  display: block;
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: text; }
  .input-field__legend {
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase; }
  .input-field__input-container {
    position: relative;
    z-index: 1; }
    .input-field__input-container::before, .input-field__input-container::after {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: -1;
      display: block;
      height: 1px;
      background: #1b1f27;
      content: "";
      transition: transform 0.2s ease-in-out; }
    .input-field__input-container::before {
      transform: scaleX(1);
      transition-delay: 0.2s;
      transform-origin: right center; }
    .input-field__input-container::after {
      bottom: -3px;
      height: 2px;
      background: #00aeef;
      transform: scaleX(0);
      transition-delay: 0s;
      transform-origin: left center; }
    .input-field__input-container.focused::before {
      transform: scaleX(0);
      transition-delay: 0s; }
    .input-field__input-container.focused::after {
      transform: scaleX(1);
      transition-delay: 0.2s; }
    .input-field__input-container:active::after {
      background: #20242f; }
  .input-field__input {
    padding: 0;
    margin: 0;
    font: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    white-space: nowrap;
    background: none;
    border: 0;
    border-radius: 0;
    appearance: none;
    width: 100%;
    height: 30px;
    padding: 5px 0;
    transition: height 0.1s ease-in-out; }
  .input-field__error {
    font-size: 14px;
    font-weight: 900;
    color: #e74c3c; }
  .input-field.empty:not(.focused) .input-field__input {
    height: 0; }

/**
 * MIXINS FROM BOURBON:
 */
.page {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  min-width: 360px; }
  .page__container, .page__small--container {
    flex: 0 1 auto;
    width: 1060px;
    max-height: calc(100% - 80px);
    margin-bottom: 40px;
    color: #43414e;
    background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)) 0 100%;
    background-attachment: local, local, scroll, scroll;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    border-radius: 5px;
    box-shadow: 0 0 85px rgba(0, 0, 0, 0.5); }
    .page__container.loader--cloak, .loader--cloak.page__small--container {
      min-height: 200px; }
    @media (max-width: 1060px) {
      .page__container-wrapper {
        width: 600px; } }
  .page__small--container {
    width: 680px; }
  .page__section {
    margin: 40px 60px; }
    @media (max-width: 680px) {
      .page__section {
        margin: 30px 20px; } }
    .page__section--flush {
      margin-right: 0;
      margin-left: 0; }
    .page__section--map {
      height: 300px; }
    .page__section--alternate {
      background: #f9f9f9;
      border-radius: 0 0 5px 5px; }
    .page__section--header {
      margin-top: 20px;
      margin-bottom: 20px; }
  .page__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px;
    margin-bottom: 40px; }
    @media (max-width: 680px) {
      .page__header {
        margin: 30px 20px; } }
  .page__title {
    flex: 1 0 200px; }
  .page__close-button {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    text-align: center; }
    .page__close-button__icon svg {
      display: inline-block;
      width: 15px;
      height: 15px; }
  .page--spacious .page__header,
  .page--spacious .page__section {
    margin: 70px 90px; }
    @media (max-height: 800px) {
      .page--spacious .page__header,
      .page--spacious .page__section {
        margin: 50px 60px; } }
    @media (max-height: 700px) {
      .page--spacious .page__header,
      .page--spacious .page__section {
        margin: 40px; } }
    @media (max-width: 450px) {
      .page--spacious .page__header,
      .page--spacious .page__section {
        margin: 30px 20px; } }

.white-page {
  position: absolute;
  top: 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: 14px;
  color: #43414e;
  background: #fff fixed; }
  .white-page__frame {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    overflow: hidden; }
    @media (max-width: 599px) {
      .white-page__frame {
        align-items: center;
        flex-direction: column; } }
  .white-page__container, .white-page__small--container {
    flex: 0 1 auto;
    width: 1060px;
    max-height: calc(100% - 80px); }
  .white-page__small--container {
    width: 680px; }
    @media (max-width: 599px) {
      .white-page__small--container {
        width: inherit;
        margin-right: 10px;
        margin-left: 10px; } }
  .white-page__section {
    margin: 40px 60px; }
    .white-page__section--flush {
      margin-right: 0;
      margin-left: 0; }
    .white-page__section--map {
      height: 300px; }
    .white-page__section--alternate {
      background: #f9f9f9; }
    .white-page__section--header {
      margin-top: 20px;
      margin-bottom: 20px; }
    @media (max-width: 599px) {
      .white-page__section {
        margin-right: 20px;
        margin-left: 20px; } }
  .white-page__header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin: 60px; }
    @media (max-width: 599px) {
      .white-page__header {
        margin-right: 20px;
        margin-left: 20px; } }
  .white-page__icon {
    min-width: 118;
    margin: 60px 20px 0; }
  .white-page__title {
    flex: 1 0;
    padding-bottom: 10px;
    line-height: normal;
    color: #20242e; }
    @media (max-width: 599px) {
      .white-page__title {
        font-size: 32px; } }
  .white-page--spacious .white-page__header,
  .white-page--spacious .white-page__section {
    margin: 70px 90px; }
    @media (max-height: 800px) {
      .white-page--spacious .white-page__header,
      .white-page--spacious .white-page__section {
        margin: 50px 60px; } }
    @media (max-height: 700px) {
      .white-page--spacious .white-page__header,
      .white-page--spacious .white-page__section {
        margin: 40px; } }

.tabs--shadowed {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); }

.tabs__items {
  margin: 30px 0; }

.tab {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold; }
  .tab__item {
    margin-top: 40px;
    margin-bottom: 40px; }

.dropdown {
  width: 32px;
  height: 32px;
  border-radius: 4px; }
  .dropdown__open {
    border-radius: 4px; }
  .dropdown__submenu {
    display: block; }

.theme--light.v-list {
  background: #fff; }

tr .dropdown {
  margin: 0 16px; }
  @media (max-width: 850px) {
    tr .dropdown {
      margin: 0; } }

tr:hover .dropdown {
  border: solid 1px rgba(255, 255, 255, 0.3); }
  tr:hover .dropdown:hover {
    border: solid 1px white; }

.notifications__container {
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  background-color: #fff; }

.notifications__loader {
  height: 120px; }

.notifications__item {
  margin-bottom: 10px; }

.notifications__title {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1rem; }

.notifications__text {
  padding-bottom: 8px;
  font-size: .75rem; }

.notifications__link {
  white-space: nowrap; }

.notifications__footer {
  font-size: .625rem;
  color: rgba(0, 0, 0, 0.6); }

.notifications__badge.v-badge {
  margin-top: 0; }

.main-header__logo {
  position: absolute;
  top: 0;
  left: 3.90625vw; }
  .main-header__logo__image svg {
    width: 120px;
    height: 40px;
    fill: #fff; }
  .main-header__logo__headline {
    width: 0;
    height: 0;
    overflow: hidden; }

.main-header__navigation {
  position: absolute;
  top: 3.125vw; }

.main-header__links {
  position: absolute;
  right: 6.51042vw; }

.main-header__toolbar {
  position: absolute;
  right: calc((145 / 1920 * 100vw) - 12px); }

[v-cloak] {
  display: none; }
