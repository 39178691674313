.loader {
	position: relative;

	&::before,
	&::after {
		position: absolute;
		display: block;
		content: "";
	}

	&::before {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: z(loader);
		background: rgba(#fff, .75);
	}

	&::after {
		top: calc(50% - 32px);
		left: calc(50% - 32px);
		z-index: z(loader-spinner);
		width: 64px;
		height: 64px;
		pointer-events: none;
		background: url("data:image/svg+xml;charset=UTF-8,<svg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'><path d='M9.12 32c0 12.637 10.243 22.88 22.88 22.88 12.637 0 22.88-10.243 22.88-22.88C54.88 19.363 44.638 9.12 32 9.12' stroke='#{$color-blue-dark}' stroke-width='2.5' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>");
		background-size: 64px 64px;
		animation: loader .75s ease-in-out infinite alternate;
	}

	&--small {
		&::after {
			top: calc(50% - 16px);
			left: calc(50% - 16px);
			width: 32px;
			height: 32px;
			background-size: 32px 32px;
		}
	}

	&--alternate {
		&::before {
			background: rgba($color-blue-dark, .75);
		}

		&::after {
			background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'><path d='M9.12 32c0 12.637 10.243 22.88 22.88 22.88 12.637 0 22.88-10.243 22.88-22.88C54.88 19.363 44.638 9.12 32 9.12' stroke='#fff' stroke-width='2.5' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>");
		}
	}

	&--cloak {
		> * {
			display: none;
		}
	}
}
