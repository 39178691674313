* {
	outline: none;
	box-sizing: border-box;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&::after,
	&::before {
		box-sizing: inherit;
	}
}

// ::selection {
//     color: #fff;
//     background: $color-green-basic;
// }

// ::-moz-selection {
//     color: #fff;
//     background: $color-green-basic;
// }

html {
	height: 100%;
	overflow: auto;
}

body {
	min-height: 100%;
	padding-top: 200px;
	font-family: $font-stack-default;
	font-weight: $font-weight-regular;
	color: #fff;
	background: linear-gradient(45deg, #3b2634, #202631) fixed;

	@include break(xs) {
		padding-top: 110px;
	}
}

button {
	@include formElementStyleReset;
	cursor: pointer;
}

label {
	cursor: pointer;
}

input[type="button"],
input[type="submit"],
button,
select {
	user-select: none;
}

p {
	margin-top: .4em;
	margin-bottom: .4em;
	font-weight: $font-weight-regular;
}

b,
strong {
	font-weight: $font-weight-black;
}

hr {
	height: 1px;
	padding: 0;
	margin: 20px 0;
	background: $color-blue-basic;
	border: 0;
}

small {
	color: $color-gray-light;
}

.visually-hidden {
	@include visuallyHidden;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}
