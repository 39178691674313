@import "timing-functions";

@mixin formElementStyleReset {
	padding: 0;
	margin: 0;
	font: inherit;
	line-height: inherit;
	color: inherit;
	text-transform: inherit;
	white-space: nowrap;
	background: none;
	border: 0;
	border-radius: 0;
	appearance: none;
}

@mixin fancyBorderAnimationOnInteraction($selector: ":hover", $horizontal-margins: -2px) {
	&::before,
	&::after {
		position: absolute;
		right: $horizontal-margins;
		bottom: -2px;
		left: $horizontal-margins;
		z-index: -1;
		display: block;
		height: 1px;
		background: $color-blue-dark;
		content: "";
		transition: transform $transition-duration-basic $transition-timing-function-default;
	}

	&::before {
		transform: scaleX(1);
		transition-delay: $transition-duration-basic;
		transform-origin: right center;
	}

	&::after {
		bottom: -3px;
		height: 2px;
		background: $color-blue-light;
		transform: scaleX(0);
		transition-delay: 0s;
		transform-origin: left center;
	}

	&#{$selector} {
		&::before {
			transform: scaleX(0);
			transition-delay: 0s;
		}

		&::after {
			transform: scaleX(1);
			transition-delay: $transition-duration-basic;
		}
	}

	&:active {
		&::after {
			background: $color-blue-basic;
		}
	}
}

@mixin break($breakpoint) {
	@media (max-width: #{map-get($breakpoints, $breakpoint) * 1px}) {
		@content;
	}
}

@mixin visuallyHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	border: 0;
	opacity: 0;
}

@mixin isFirefox {
	// 😢
	@-moz-document url-prefix() {
		@content;
	}
}

/**
 * MIXINS FROM BOURBON:
 */

@mixin placeholder {
	$placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

	@each $placeholder in $placeholders {
		&:#{$placeholder}-placeholder {
			@content;
		}
	}
}

@mixin hidpi($ratio: 1.3) {
	@media only screen and (-webkit-min-device-pixel-ratio: $ratio),
	only screen and (min--moz-device-pixel-ratio: $ratio),
	only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
	only screen and (min-resolution: round($ratio * 96dpi)),
	only screen and (min-resolution: $ratio * 1dppx) {
		@content;
	}
}

@mixin clearfix {
	&::after {
		display: table;
		clear: both;
		content: "";
	}
}
