.white-page {
	$m: #{&};

	position: absolute;
	top: 0;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	height: 100%;
	overflow: auto;
	font-size: 14px;

	color: $color-gray-dark;
	background: #fff fixed;

	&__frame {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		justify-content: center;
		margin: auto;
		overflow: hidden;

		@include break(xs) {
			align-items: center;
			flex-direction: column;
		}
	}

	&__container {
		// the actual white window
		flex: 0 1 auto;
		width: 1060px;
		max-height: calc(100% - 80px);
	}

	&__small--container {
		// the actual white window
		@extend #{$m}__container;
		width: 680px;

		@include break(xs) {
			width: inherit;
			margin-right: 10px;
			margin-left: 10px;
		}
	}

	&__section {
		margin: 40px 60px;

		&--flush {
			margin-right: 0;
			margin-left: 0;
		}

		&--map {
			height: 300px;
		}

		&--alternate {
			background: $color-gray-extralight;
		}

		&--header {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		@include break(xs) {
			margin-right: 20px;
			margin-left: 20px;
		}
	}

	&__header {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
		margin: 60px;

		@include break(xs) {
			margin-right: 20px;
			margin-left: 20px;
		}
	}

	&__icon {
		min-width: 118;
		margin: 60px 20px 0;
	}

	&__title {
		flex: 1 0;
		padding-bottom: 10px;
		line-height: normal;
		color: #20242e;

		@include break(xs) {
			font-size: 32px;
		}
	}

	&--spacious {
		#{$m}__header,
		#{$m}__section {
			margin: 70px 90px;

			@media (max-height: 800px) {
				margin: 50px 60px;
			}

			@media (max-height: 700px) {
				margin: 40px;
			}
		}
	}
}
