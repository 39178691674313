@import "../helpers/variables";
@import "../helpers/mixins";

.icon {
	svg {
		position: relative;
		bottom: 1px;
		display: inline-block;
		vertical-align: middle;
		transition: fill $transition-duration-quick $transition-timing-function-default;
	}

	&--white svg {
		fill: #fff;
	}

	&--pale {
		opacity: .15;
	}

	&--large svg {
		width: 30px;
		height: 30px;
	}

	&--medium svg {
		width: 25px;
		height: 25px;
	}

	&--small svg {
		width: 20px;
		height: 20px;
	}

	&--smaller svg {
		width: 15px;
		height: 15px;
	}

	&--tiny svg {
		width: 10px;
		height: 10px;
	}

	&--huge svg {
		width: 150px;
		height: 40px;
	}

	&--payment svg {
		width: 150px;
		height: 40px;

		@include break(xs) {
			width: 120px;
			height: 40px;
		}
	}

	&--on-left svg {
		margin-right: 1em;
	}

	&--on-left-nav svg {
		margin-right: .5em;
	}

	&--on-right svg {
		margin-left: 1em;
	}

	&--placeholder svg {
		opacity: 0;
		visibility: hidden;
	}
}
