@import "../helpers/variables";

// Paddings and margins for checkbox and dropdown
$large-box: 20px;
$medium-box: 15px;

.device-grid {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: space-around;

	&--align-left {
		justify-content: flex-start;
	}

	&__group {
		position: relative;
		top: 1vw;
		margin-left: 75 / 1920 * 100vw;

		&--bottom-row {
			width: 100%;
			border: solid 1px rgba(#fff, .14);
		}

		&--no-bottom-row {
			width: 100%;
			border: solid 1px rgba(#fff, 0);
		}
	}

	&__device {
		display: flex;
		flex-basis: content;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 0;

		padding-top: 30px;
		margin: 1.5vw 2px;

		@media (max-width: $grid-lg-breakpoint) {
			padding-top: 10px;
		}

		@media (max-width: $grid-md-breakpoint) {
			padding-top: 0;
		}

		@media (max-width: $grid-sd-breakpoint) {
			margin: 3vw 2px;
		}

		text-align: center;
		border: 4px solid transparent;

		&--add {
			align-items: center;
			padding: 30px 0 0;

			@media (max-width: $grid-lg-breakpoint) {
				padding-top: 10px;
			}

			@media (max-width: $grid-md-breakpoint) {
				padding: 30px 30px 25px;
			}
		}

		li {
			display: block;
			float: left;
		}

		.device-grid__phone {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&:hover {
			background-color: rgba(0, 0, 0, .1);
			border-radius: 8px;
			box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .05);

			.cbx span:first-child {
				border-color: rgba(#fff, .75);
			}
		}

		&--grouped {
			margin-right: .6vw;
			margin-left: .6vw;

			@media (max-width: $grid-xl-breakpoint) {
				margin-right: 1vw;
				margin-left: 1vw;
			}

			@media (max-width: $grid-lg-breakpoint) {
				margin-right: 1.2vw;
				margin-left: 1.2vw;
			}

			@media (max-width: $grid-md-breakpoint) {
				margin-right: .9vw;
				margin-left: .9vw;
			}

			@media (max-width: $grid-sm-breakpoint) {
				margin-right: 4.2vw;
				margin-left: 4.2vw;
			}

			@media (max-width: $grid-xs-breakpoint) {
				margin-right: 2.7vw;
				margin-left: 2.7vw;
			}

			@media (max-width: $grid-xxs-breakpoint) {
				margin-right: 1.5vw;
				margin-left: 1.5vw;
			}

			@media (max-width: $grid-sd-breakpoint) {
				margin-right: 2px;
				margin-left: 2px;
			}
		}
	}

	&__dropdown {
		position: relative;
		top: -6px;
		padding: 0 $large-box;

		@media (max-width: $grid-lg-breakpoint) {
			top: $grid-lg-margin-base - 8px;
			padding: 0 $medium-box;
		}

		@media (max-width: $grid-md-breakpoint) {
			top: $grid-md-margin-base - 10px;
		}

		@media (max-width: $grid-sd-breakpoint) {
			top: $grid-sd-margin-base - 11px;
		}
	}

	&__checkbox {
		position: relative;
		top: -1px;
		padding: 0 $large-box + 2;

		@media (max-width: $grid-lg-breakpoint) {
			top: $grid-lg-margin-base - 2px;
			padding: 0 $medium-box + 2;
		}

		@media (max-width: $grid-md-breakpoint) {
			top: $grid-md-margin-base - 4px;
		}

		@media (max-width: $grid-sd-breakpoint) {
			top: $grid-sd-margin-base - 5px;
		}
	}

	&__status {
		padding-top: 10px;

		font-weight: $font-weight-regular;
		text-transform: unset;
	}

	&__screen {
		display: flex;
		align-items: center;
		justify-content: center;
		width: $grid-phone-width;
		height: $grid-phone-height;
		-webkit-transform: scale(1);
		-webkit-transition: all .1s ease-out;
		-moz-transform: scale(1);
		-moz-transition: all .1s ease-out;
		transform: scale(1);
		transition: all .1s ease-out;

		@media (max-width: $grid-xl-breakpoint) {
			width: 14rem;
			height: 30rem;
		}

		@media (max-width: $grid-lg-breakpoint) {
			width: 12rem;
			height: 26rem;
		}

		@media (max-width: $grid-md-breakpoint) {
			width: 10rem;
			height: 21rem;
		}

		@media (max-width: $grid-xxs-breakpoint) {
			width: 9rem;
			height: 19rem;
		}

		&-add {
			margin-top: $grid-xl-margin-base + 16px;

			@media (max-width: $grid-lg-breakpoint) {
				margin-top: $grid-lg-margin-base;
			}

			@media (max-width: $grid-md-breakpoint) {
				margin-top: $grid-md-margin-base;
			}

			@media (max-width: $grid-sd-breakpoint) {
				margin-top: $grid-sd-margin-base;
			}
		}
	}

	&__device--add--title {
		font-family: $font-family-druk;
		font-size: 28px;
		color: #fff;

		@media (max-width: $grid-xl-breakpoint) {
			font-size: 24px;
		}

		@media (max-width: $grid-md-breakpoint) {
			font-size: 20px;
		}

		@media (max-width: $grid-sm-breakpoint) {
			font-size: 16px;
		}
	}

	&__device--add &__screen {
		transform: scale(.9);
	}

	&__device--link &__screen__phone {
		cursor: pointer;
	}

	&__info {
		margin: $grid-xl-margin-base 0;

		@media (max-width: $grid-lg-breakpoint) {
			margin: $grid-lg-margin-base 0;
		}

		@media (max-width: $grid-md-breakpoint) {
			margin: $grid-md-margin-base 0;
		}

		@media (max-width: $grid-sd-breakpoint) {
			margin: $grid-sd-margin-base 0;
		}

		&-row {
			margin: 10px 0;

			@media (max-width: $grid-sd-breakpoint) {
				margin: 6px 0;
			}

			&--grey {
				color: $color-gray-light;
			}
		}
	}

	&__name {
		display: inline-flex;
		margin-top: 2px;
		margin-bottom: $grid-xl-margin-base;
		font-weight: $font-weight-semibold;
		color: #fff;
		text-transform: uppercase;

		@media (max-width: $grid-lg-breakpoint) {
			margin: $grid-lg-margin-base 0;
		}

		@media (max-width: $grid-md-breakpoint) {
			margin: $grid-md-margin-base 0;
		}

		@media (max-width: $grid-sd-breakpoint) {
			margin: $grid-sd-margin-base 0;
		}
	}

	&__settings-button {
		margin-right: -29px;
		opacity: 0;
		transition: opacity $transition-duration-basic $transition-timing-function-default;
	}

	&__device:hover &__settings-button {
		opacity: 1;
	}

}

.v-application ol,
.v-application ul {
	padding-left: 0;
}

.device-grid .dropdown {
	border: solid 1px rgba(#fff, .14);

	&:hover {
		border: solid 1px rgba(#fff, 1);
	}
}

.checked-device {
	background-color: rgba(20, 92, 255, .1);
	border: 4px solid #145cff;
	border-radius: 8px;
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .05);

	&__screen {
		-webkit-transform: scale(.9);
		-webkit-transition: all .1s ease-in;
		-moz-transform: scale(.9);
		-moz-transition: all .1s ease-in;
		transform: scale(.9);
		transition: all .1s ease-in;
	}

	&:hover {
		background-color: rgba(20, 92, 255, .25);
	}
}

// Custom Material Design Checkbox
.cbx {
	margin: auto;
	-webkit-user-select: none;
	cursor: pointer;
	user-select: none;
}

.cbx span {
	display: inline-block;
	vertical-align: middle;
	transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
	position: relative;
	width: 21px;
	height: 21px;
	vertical-align: middle;
	border: 2px solid rgba(#fff, .15);
	border-radius: 3px;
	transform: scale(1);
	transition: all .2s ease;
}

.cbx span:first-child svg {
	position: absolute;
	top: 4px;
	left: 3px;
	transform: translate3d(0);
	transition: all .1s ease;
	fill: none;
	stroke: #fff;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 16px;
	stroke-dashoffset: 16px;
}

.cbx span:first-child::before {
	display: block;
	width: 100%;
	height: 100%;
	background: #506eec;
	border-radius: 50%;
	content: "";
	opacity: 1;
	transform: scale(0);
}

.cbx span:last-child {
	padding-left: 8px;
}

.inp-cbx:checked + .cbx span:first-child {
	background: #145cff;
	border-color: #145cff;
	animation: wave .4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
	stroke-dashoffset: 0;
}

.inp-cbx:checked + .cbx span:first-child::before {
	opacity: 0;
	transform: scale(3.5);
	transition: all .6s ease;
}

@keyframes wave {
	50% {
		transform: scale(.9);
	}
}
