.button {
	&--edit {
		.icon {
			fill: $color-gray-light;
		}

		&:hover,
		&:focus,
		&:active {
			.icon {
				fill: $color-blue-dark;
			}
		}
	}

	&--settings {
		.icon {
			fill: rgba(#fff, .5);
		}

		&:hover,
		&:focus {
			.icon {
				fill: rgba(#fff, 1);
			}
		}
	}

	&--primary {
		padding: 1em;
		font-family: $font-family-default;
		font-size: 18px;
		font-weight: $font-weight-black;
		text-transform: uppercase;
		background: $color-blue-light;
		border-radius: $border-radius-large;
	}

	&--secondary {
		padding: 1em;
		font-family: $font-family-default;
		font-size: 15px;
		font-weight: $font-weight-black;
		color: #fff;
		text-transform: uppercase;
		background: $color-blue-basic;
		border-radius: $border-radius-large;
	}

	&--huge {
		padding: 1.75em 1em;
		border-radius: $border-radius-huge;
	}

	&--flush {
		width: 100%;
	}
}

.v-btn {
	margin: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
	padding: 0 30 / 1920 * 100vw;
}

.v-btn:not(.v-btn--round).v-size--large {
	height: 44px;
	padding: 0 32px;
	font-size: 15px;
}

.v-btn:not(.v-btn--round).v-size--x-large {
	height: 56px;
}

.v-btn__content {
	font-weight: bold;
	letter-spacing: normal;
}
