.modal {
	$m: #{&};

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: z(modal);
	display: grid;
	overflow: auto;
	background: rgba($color-blue-basic, .5);

	&__container {
		width: 680px;
		margin: auto;

		&--row {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 40px 0;
		}

		@include break(modal) {
			width: inherit;
			margin: auto 0;
		}
	}

	&__body {
		// the actual white window we call "modal"
		position: relative;
		width: 530px;
		overflow: hidden;
		color: $color-blue-basic;
		background: linear-gradient(#fff 30%, rgba(#fff, 0)), linear-gradient(rgba(#fff, 0), #fff 70%) 0 100%,
			radial-gradient(farthest-side at 50% 0, rgba(#000, .35), rgba(#000, 0)), radial-gradient(farthest-side at 50% 100%, rgba(#000, .35), rgba(#000, 0)) 0 100%;
		background-attachment: local, local, scroll, scroll;
		background-color: #fff;
		background-repeat: no-repeat;
		background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
		border-radius: $border-radius-large;
		box-shadow: 0 0 85px rgba(#000, .5);

		&.loader--cloak {
			// Add minimum height when only the loader is present
			min-height: 200px;
		}

		@include break(modal) {
			width: inherit;
		}
	}

	&__footer {
		position: relative;
		display: flex;
		width: 530px;
		padding: 3em 0;

		&--spread {
			justify-content: space-between;
		}

		@include break(modal) {
			align-items: center;
			flex-direction: column;
			width: inherit;
			line-height: 30px;
			text-align: center;
		}
	}

	&__section {
		margin: 35px;

		@media (max-height: 800px) {
			margin: 25px;
		}

		@media (max-height: 700px) {
			margin: 20px;
		}

		&--flush {
			margin-right: 0;
			margin-left: 0;
		}

		&--map {
			height: 300px;
		}

		&--alternate {
			background: $color-gray-extralight;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		flex: 1 0 200px;
	}

	&__close-button {
		flex: 0 0 40px;
		width: 40px;
		height: 40px;
		text-align: center;

		&__icon svg {
			display: inline-block;
			width: 15px;
			height: 15px;
		}
	}

	&--spacious {
		#{$m}__body,
		#{$m}__footer {
			width: 680px;

			@include break(modal) {
				flex-direction: column;
				width: inherit;
				padding-bottom: 0;
			}
		}

		#{$m}__header,
		#{$m}__section {
			margin: 70px 90px;

			@media (max-height: 800px) {
				margin: 50px 60px;
			}

			@media (max-height: 700px) {
				margin: 40px;
			}

			@include break(modal) {
				width: inherit;
				padding-right: 15px;
				padding-left: 15px;
				margin: 40px 0;
			}
		}
	}
}
