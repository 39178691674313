@import "../helpers/variables";

$phone-svg-width: 124px;
$phone-svg-height: 260px;

@mixin phone-thumbnail($width) {
	width: $width;
	height: #{$width / $phone-svg-width * $phone-svg-height};
	padding: round($width / 250);
	filter: drop-shadow(0 0 #{round($width / 10)} rgba(#000, .5));
}

.phone {
	$ph: ".phone";
	$bg-properties: center no-repeat;
	$bg-properties-thumbnail: center center / contain no-repeat;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: svg-load("phone.svg") $bg-properties;
	background-size: 100% 100%;

	&__screen {
		display: block;
		max-width: $grid-phone-width;
		max-height: $grid-phone-height;
		filter: drop-shadow(0 0 #{round($grid-phone-width / 10)} rgba(#000, .5));
		object-fit: scale-down;

		@media (max-width: $grid-xl-breakpoint) {
			max-width: 14rem;
			max-height: 30rem;
		}

		@media (max-width: $grid-lg-breakpoint) {
			max-width: 12rem;
			max-height: 26rem;
		}

		@media (max-width: $grid-md-breakpoint) {
			max-width: 10rem;
			max-height: 21rem;
		}

		@media (max-width: $grid-xxs-breakpoint) {
			max-width: 9rem;
			max-height: 19rem;
		}

		&-thumbnail {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: fill;
			background-color: black;
		}
	}

	&--thumbnail {
		@include phone-thumbnail($phone-svg-width * .18);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 1px;
		margin: auto;
		background: none;
	}

	&--medium {
		background-size: 100% 100%;
	}

	&--add {
		@include phone-thumbnail($phone-svg-width * 2.25);
		position: relative;
		padding-top: 46px;
		padding-bottom: 46px;
		background-image: svg-load("phone-add-button.svg"), svg-load("phone-add-base.svg");

		@media (max-width: $grid-xl-breakpoint) {
			width: 14rem;
			height: 30rem;
		}

		@media (max-width: $grid-lg-breakpoint) {
			width: 12rem;
			height: 26rem;
		}

		@media (max-width: $grid-md-breakpoint) {
			width: 10rem;
			height: 21rem;
		}

		@media (max-width: $grid-xxs-breakpoint) {
			width: 9rem;
			height: 19rem;
		}

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background: svg-load("phone-add-button-hover.svg") $bg-properties;
			content: "";
			opacity: 0;
			transform: scale(.95) translateZ(0);
			transition: opacity $transition-duration-basic $transition-timing-function-default, transform $transition-duration-basic $transition-timing-function-default;
		}

		&:hover::after {
			opacity: 1;
			transform: scale(1) translateZ(0);
		}
	}
}
