@import "../helpers/variables";
@import "../helpers/mixins";

.page {
	$m: #{&};

	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	min-width: 360px;

	&__container {
		// the actual white window
		flex: 0 1 auto;
		width: 1060px;
		max-height: calc(100% - 80px);
		margin-bottom: 40px;
		color: $color-gray-dark;
		background: linear-gradient(#fff 30%, rgba(#fff, 0)), linear-gradient(rgba(#fff, 0), #fff 70%) 0 100%,
			radial-gradient(farthest-side at 50% 0, rgba(#000, .35), rgba(#000, 0)), radial-gradient(farthest-side at 50% 100%, rgba(#000, .35), rgba(#000, 0)) 0 100%;
		background-attachment: local, local, scroll, scroll;
		background-color: #fff;
		background-repeat: no-repeat;
		background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
		border-radius: $border-radius-large;
		box-shadow: 0 0 85px rgba(#000, .5);

		&.loader--cloak {
			// Add minimum height when only the loader is present
			min-height: 200px;
		}

		&-wrapper {
			@include break(page) {
				width: 600px;
			}
		}
	}

	&__small--container {
		// the actual white window
		@extend #{$m}__container;
		width: 680px;
	}

	&__section {
		margin: 40px 60px;

		@include break(modal) {
			margin: 30px 20px;
		}

		&--flush {
			margin-right: 0;
			margin-left: 0;
		}

		&--map {
			height: 300px;
		}

		&--alternate {
			background: $color-gray-extralight;

			border-radius: 0 0 5px 5px;
		}

		&--header {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 60px;
		margin-bottom: 40px;

		@include break(modal) {
			margin: 30px 20px;
		}
	}

	&__title {
		flex: 1 0 200px;
	}

	&__close-button {
		flex: 0 0 40px;
		width: 40px;
		height: 40px;
		text-align: center;

		&__icon svg {
			display: inline-block;
			width: 15px;
			height: 15px;
		}
	}

	&--spacious {
		#{$m}__header,
		#{$m}__section {
			margin: 70px 90px;

			@media (max-height: 800px) {
				margin: 50px 60px;
			}

			@media (max-height: 700px) {
				margin: 40px;
			}

			@media (max-width: 450px) {
				margin: 30px 20px;
			}
		}
	}
}
