.view-switcher {
	position: fixed;
	right: 40px;
	bottom: 60px;
	display: flex;

	&__item {
		position: relative;
	}

	&__input {
		@include visuallyHidden;
	}

	&__button {
		$box-shadow1: 0 0 50px;
		$box-shadow2: 0 0 20px;

		display: flex;
		align-items: center;
		width: 56px;
		height: 40px;
		text-align: center;
		border-radius: $border-radius-basic;
		box-shadow: $box-shadow1 transparent, $box-shadow2 transparent;
		transition: box-shadow $transition-duration-slower $transition-timing-function-default;

		&:hover {
			box-shadow: $box-shadow1 rgba(#000, .4), $box-shadow2 rgba(#000, .1);
		}
	}

	&__icon {
		margin: 0 auto;
	}

	&__icon svg {
		display: block;
		width: 24px;
		height: 20px;
		opacity: .25;
		transition: opacity $transition-duration-slower $transition-timing-function-default;
		fill: #fff;
	}

	&__button:hover &__icon svg,
	&__input:checked + &__button &__icon svg {
		opacity: 1;
	}

	&__tooltip {
		position: absolute;
		bottom: calc(100% + 25px);
		left: 50%;
		display: none;
		min-width: 120px;
		transform: translateX(-50%);
	}

	&__button:hover + &__tooltip {
		display: block;
	}
}
